<template>
  <div
    v-editable="blok"
    class="NewsletterSignup"
  >
    <div
      class="flex justify-center p-32 desk:p-64 w-full relative bg-lighter"
      :class="{
        'layout-container' : !blok.fullWidth,
      }"
    >
      <div
        v-if="blok.backgroundImage && blok.backgroundImage.filename"
        class="w-full h-full absolute top-0 left-0"
      >
        <video
          v-if="checkIfVideoFile(blok.backgroundImage.filename)"
          width="w-full"
          height="h-full"
          class="w-full h-full object-cover"
          loop
          muted
          autoplay
          playsinline
        >
          <source :src="blok.backgroundImage.filename" type="video/mp4">
        </video>
        <nuxt-img
          v-else-if="blok.backgroundImage && blok.backgroundImage.filename"
          preset="standard"
          loading="lazy"
          sizes="sm:100vw desk:1260px"
          class="h-full w-full object-cover"
          :src="blok.backgroundImage.filename"
          :alt="blok.backgroundImage.alt"
        />
      </div>
      <div class="relative flex flex-col items-center gap-12 bg-lightest p-32 desk:p-64 w-full max-w-[648px]">
        <nuxt-img
          v-if="blok.splashImage && blok.splashImage.filename"
          preset="standard"
          loading="lazy"
          sizes="sm:100vw desk:1260px"
          class="absolute -top-16 -left-16 desk:-top-24 desk:-left-24 w-64 h-64 desk:w-96 desk:h-96"
          :src="blok.splashImage.filename"
          :alt="blok.splashImage.alt"
        />
        <div class="type-tagline dotted-border text-center">
          {{ blok.tagline }}
        </div>
        <div class="text-center type-headline-xl desk:type-headline-3xl">
          {{ blok.headline }}
        </div>
        <div class="text-center text-sm desk:text-base wysiwyg">
          <ExtendedWysiwyg :content="blok.content" />
        </div>
        <div v-if="success" class="type-headline-sm mt-16">
          {{ $t('newsletter.success') }}
        </div>
        <Form
          v-else
          class="w-full"
          @submit="onNewsletterSignup"
        >
          <Field
            v-slot="{ field, meta }"
            name="email"
            :rules="validateEmail"
            :model-value="email"
          >
            <TextInput
              v-bind="field"
              v-model="email"
              server-name="email"
              type="email"
              :label="blok.placeholder"
              :show-error="meta.validated && !meta.valid"
              :errors="meta.errors"
              autocomplete="email"
            />
          </Field>
          <button
            v-if="blok.buttonText"
            class="btn btn--primary w-full mt-12"
          >
            {{ blok.buttonText }}
          </button>
          <div v-if="error" class="type-headline-xs mt-8 text-criticalDark">
            {{ lastError?.message || $t('error.generic') }}
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { StoryBlokAsset, StoryBlokPropBase } from '~/constants/types/storyblok';
import { checkIfVideoFile } from '~/util/imageUtil';
import { ISbRichtext } from 'storyblok-js-client';
import TextInput from '~/components/form-elements/TextInput.vue';
import { Field, Form } from 'vee-validate';
import useValidation from '~/composeables/useValidation';
import useApiFetch from '~/composeables/useApiFetch';

type Props = StoryBlokPropBase & {
  tagline: string;
  headline: string;
  content: ISbRichtext;
  placeholder: string;
  buttonText: string;
  backgroundImage: StoryBlokAsset;
  fullWidth: boolean;
  splashImage: StoryBlokAsset,
}

const Props = defineProps<{
  blok: Props,
}>();

const { apiPost, lastError } = useApiFetch();

const {
  validateEmail,
} = useValidation();

const email = ref('');
const success = ref(false);
const error = ref(false);

const onNewsletterSignup = async() => {
  error.value = false;
  success.value = false;
  const res = await apiPost('/newsletter/signup', {
    email: email.value,
  });

  if (res) {
    success.value = true;
  } else {
    error.value = true;
  }
};

</script>

<style scoped lang="postcss">

</style>
